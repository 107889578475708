

import { useRouter } from "vue-router";
import { defineComponent, onMounted, ref, Ref, watch} from "vue";
import Title from "@/components/Title.vue";
import Button from "@/components/Button.vue"
import Api from "@/services/Api";
import ListVehicles from "./components/ListVehicles.vue";

export default defineComponent({
  name: "VeiculosRevisao",
  components: {
    Title,
    Button,
    ListVehicles
  },
  emits: [],
  setup() {
    const router = useRouter();
    const arrayVeiculos: Ref<any[]> = ref([]);
    const arrayMarcas: Ref<any[]> = ref([]);
    const arrayPrioridade: Ref<any[]> = ref([]);
    const prioridade: Ref<string | "null"> = ref("");
    const marca: Ref<string | ""> = ref("");
    const qtd: Ref<number | 40> = ref(40);
    const startDate: Ref<string> = ref('');
    const endDate: Ref<string> = ref('');
    const loadingList: Ref<boolean> = ref(false)

    async function gerar() {
      loadingList.value = true
      try {
        const { data } = await Api.get("getVeiculosRevisao", { prioridade : prioridade.value, qtd: qtd.value, marca : marca.value, startDate: startDate.value, endDate: endDate.value });
        arrayVeiculos.value = data;
      }catch(error){
        console.log(error)
      }finally{
        loadingList.value = false
      }
    }

    async function getMarcas(){
      try{
        const { data } = await Api.get('getAllvehicleBrand')
        arrayMarcas.value = data.marcas
      }catch(error){
        console.log(error)
      }
    }

    async function getPrioridade(){
      try {
        const { data } = await Api.get('getVeiculosPrioridade')
        arrayPrioridade.value = data
      }catch(error){
        console.log(error)
      }
    }

    onMounted(() => {
      getMarcas()
      getPrioridade()
    })

    return {
      router,
      prioridade,
      marca,
      qtd,
      startDate,
      endDate,
      gerar,
      arrayVeiculos,
      arrayMarcas,
      arrayPrioridade,
      loadingList
    };
  },
});
