

import { useRouter } from "vue-router";
import { defineComponent, ref, Ref, watch } from "vue";
import Title from "@/components/Title.vue";
import Button from "@/components/Button.vue"

export default defineComponent({
    name: "ListVehicles",
    props: {
        arrayVehicles: Object,
        loading: Boolean
    },
    components: { Title, Button },
    setup(props) {
        const router = useRouter();
        const arrayVeiculos: Ref<any[] | null> = ref(null);

        watch(
            () => props.arrayVehicles,
            () => arrayVeiculos.value = props.arrayVehicles as any
        )

        return {
            router,
            arrayVeiculos
        };
    },
});
